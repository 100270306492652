// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 登录容器的样式 */
.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
/*  background-color: #1e1e1e;*/
  border-radius: 8px;
/*  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);*/
/*  color: #fff;*/
  text-align: center;
  font-family: 'Arial', sans-serif;
}

/* 标题样式 */
.login-title {
  margin-bottom: 20px;
  font-size: 2rem;
/*  color: #fff;*/
}

/* 表单组的样式 */
.login-form-group {
  margin-bottom: 15px;
  text-align: left;
}

/* 输入框样式 */
.login-input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #555;
/*  background-color: #2e2e2e;*/
/*  color: #fff;*/
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;
}

.login-input:focus {
  border-color: #00a8cc;
}

/* 登录按钮样式 */
.login-button {
  width: 105%;
  padding: 10px;
  display: inline-block; /* 让按钮适应居中的规则 */
  border: none;
  border-radius: 4px;
  background-color: #00a8cc;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 2.5%;
  margin-top: 15px;
}

.login-button:hover {
  background-color: #007ba7;
}`, "",{"version":3,"sources":["webpack://./src/components/Login.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;AACf,+BAA+B;EAC7B,kBAAkB;AACpB,gDAAgD;AAChD,iBAAiB;EACf,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA,SAAS;AACT;EACE,mBAAmB;EACnB,eAAe;AACjB,iBAAiB;AACjB;;AAEA,WAAW;AACX;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA,UAAU;AACV;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,sBAAsB;AACxB,+BAA+B;AAC/B,iBAAiB;EACf,eAAe;EACf,aAAa;EACb,kCAAkC;AACpC;;AAEA;EACE,qBAAqB;AACvB;;AAEA,WAAW;AACX;EACE,WAAW;EACX,aAAa;EACb,qBAAqB,EAAE,eAAe;EACtC,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* 登录容器的样式 */\n.login-container {\n  max-width: 400px;\n  margin: 50px auto;\n  padding: 20px;\n/*  background-color: #1e1e1e;*/\n  border-radius: 8px;\n/*  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);*/\n/*  color: #fff;*/\n  text-align: center;\n  font-family: 'Arial', sans-serif;\n}\n\n/* 标题样式 */\n.login-title {\n  margin-bottom: 20px;\n  font-size: 2rem;\n/*  color: #fff;*/\n}\n\n/* 表单组的样式 */\n.login-form-group {\n  margin-bottom: 15px;\n  text-align: left;\n}\n\n/* 输入框样式 */\n.login-input {\n  width: 100%;\n  padding: 10px;\n  border-radius: 4px;\n  border: 1px solid #555;\n/*  background-color: #2e2e2e;*/\n/*  color: #fff;*/\n  font-size: 1rem;\n  outline: none;\n  transition: border-color 0.3s ease;\n}\n\n.login-input:focus {\n  border-color: #00a8cc;\n}\n\n/* 登录按钮样式 */\n.login-button {\n  width: 105%;\n  padding: 10px;\n  display: inline-block; /* 让按钮适应居中的规则 */\n  border: none;\n  border-radius: 4px;\n  background-color: #00a8cc;\n  font-size: 1rem;\n  cursor: pointer;\n  margin-left: 2.5%;\n  margin-top: 15px;\n}\n\n.login-button:hover {\n  background-color: #007ba7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
