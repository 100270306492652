import React, { useEffect, useState } from "react";
import './UserSelector.css';  // 引入自定义CSS文件

function UserSelector({ onUserSelect, loginuser }) {
  const [users, setUsers] = useState([]);
  const [storage, setStorage] = useState([]);
  const [totalStorageUsage, setTotalStorageUsage] = useState(0); // 记录总计用量
  const [remainningstorage, setRemainingStorage] = useState(0); // 记录总计用量

  const [su, setSU] = useState([]);
  const [startTime, setStartTime] = useState(0); // 记录总计用量
  const [end_time, setEndTime] = useState(0); // 记录总计用量
  const [n_total_cpu_all, set_n_total_cpu_all] = useState(0); // 记录总计用量
  const [n_total_gpu_all, set_n_total_gpu_all] = useState(0); // 记录总计用量
  const [total_cpu_all, set_total_cpu_all] = useState(0); // 记录总计用量
  const [total_gpu_all, set_total_gpu_all] = useState(0); // 记录总计用量

  const [daily_su_use, setDailySUUse] = useState([]);
  const [daily_su_use_date, setDailySUUseDate] = useState([]);
  const [n_total_cpu_daily, set_n_total_cpu_daily] = useState(0); // 记录总计用量
  const [n_total_gpu_daily, set_n_total_gpu_daily] = useState(0); // 记录总计用量
  const [total_cpu_daily, set_total_cpu_daily] = useState(0); // 记录总计用量
  const [total_gpu_daily, set_total_gpu_daily] = useState(0); // 记录总计用量

  const [updateStorageTime, setUpdateStorageTime] = useState(0); // 记录总计用量

  const [ncpus, setNcpus] = useState(0);
  const [ngpus, setNgpus] = useState(0);
  const [walltime, setWalltime] = useState('24:00:00');
  const [estimatedSU, setEstimatedSU] = useState(0);

  useEffect(() => {
    // 从后端获取用户列表
    const fetchUsers = async () => {
      try {
        const response = await fetch("https://nsccb.junbingao.com/user_list", {
          method: "POST",
        });
        const data = await response.json(); // 解析JSON数据
        setUsers(data); // 更新用户列表到state

        const response_storage = await fetch("https://nsccb.junbingao.com/get_storage_usage", {
          method: "POST",
        });
        const data_storage = await response_storage.json();
        // console.log(data_storage)
        setStorage(data_storage)
        setUpdateStorageTime(data_storage[0].report_date)

        // 计算总计存储用量
        const totalUsage = data_storage.reduce((sum, s) => {
          const usage = parseStorage(s.project); // 假设project字段是字符串如"619G"
          return sum + usage;
        }, 0);
        setTotalStorageUsage(totalUsage);

        // 计算剩余存储量 (总容量 - 已使用容量)
        const remaining = 312 - totalUsage;
        setRemainingStorage(remaining);

        const susum = await fetch("https://nsccb.junbingao.com/get_su_summary", {
          method: "POST",
        });
        const data_su = await susum.json(); // 解析JSON数据
        setStartTime(data_su.start_time); // 更新用户列表到state
        setEndTime(data_su.end_time); // 更新用户列表到state
        setSU(data_su.data); // 更新用户列表到state

        set_n_total_cpu_all(data_su.n_total_cpu_all)
        set_n_total_gpu_all(data_su.n_total_gpu_all)
        set_total_cpu_all(data_su.total_cpu_all)
        set_total_gpu_all(data_su.total_gpu_all)

        // 每日用量
        const response_daily = await fetch("https://nsccb.junbingao.com/get_daily_su_usage", {
          method: "POST",
        });
        const data_daily = await response_daily.json(); // 解析JSON数据
        setDailySUUse(data_daily.data); // 更新用户列表到state
        setDailySUUseDate(data_daily.data[0].report_date); // 更新用户列表到state

        set_n_total_cpu_daily(data_daily.n_total_cpu_daily)
        set_n_total_gpu_daily(data_daily.n_total_gpu_daily)
        set_total_cpu_daily(data_daily.total_cpu_daily)
        set_total_gpu_daily(data_daily.total_gpu_daily)


      } catch (error) {
        console.error("Error fetching user list:", error);
      }
    };
    fetchUsers();
  }, []);

  // 辅助函数：将存储量字符串转换为数值 (支持"619G", "1T"等单位)
  const parseStorage = (storageString) => {
    const unit = storageString.slice(-1); // 获取单位 (G, T 等)
    const value = parseFloat(storageString.slice(0, -1)); // 获取数值部分

    // 根据单位转换为GB
    switch (unit) {
      case "T":
        return value;
      case "G":
        return parseFloat((value/1024).toFixed(2));
      case "M":
        return parseFloat((value/1024/1024).toFixed(2));
      case "K":
        return parseFloat((value/1024/1024/1024).toFixed(2)); 
    }
  };

  // Helper function to convert walltime (HH:MM:SS) to hours
  const convertWalltimeToHours = (walltimeStr) => {
    const [hours, minutes, seconds] = walltimeStr.split(':').map(Number);
    return hours + minutes / 60 + seconds / 3600;
  };

  // Function to calculate SU based on inputs
  const calculateSU = () => {
    const walltimeInHours = convertWalltimeToHours(walltime || '24:00:00'); // Default 24 hours if no input
    let su = 0;
    if (parseFloat(ngpus) > 0) {
      su = 64 * parseFloat(ngpus) * walltimeInHours;
      const adjustedNcpus = 16 * parseFloat(ngpus);
      setNcpus(adjustedNcpus);
    } else {
      su = parseFloat(ncpus) * walltimeInHours;
    }
    setEstimatedSU(su.toFixed(2));
  };

  // Update SU whenever ncpus, ngpus or walltime changes
  useEffect(() => {
    calculateSU();
  }, [ncpus, ngpus, walltime]);

  if (loginuser === 'liulab') {
    return (
      <div className="user-selector-container">
          
        <header className="page-header">
          <h1 className="page-title">Liulab NSCC Usage Summary</h1>
          <h3> v0.0.1 @ Debug </h3>
        </header>

        <h2> SU Calculator </h2>

        <label>ncpus: </label>
        <input
          type="number"
          id="n_cpu_jobs"
          name="n_cpu_jobs"
          placeholder="ncpus(default 0)"
          value={ncpus}
          onChange={(e) => setNcpus(e.target.value)}
        />

        <label>ngpus: </label>
        <input
          type="number"
          id="n_gpu_jobs"
          name="n_gpu_jobs"
          placeholder="ngpus(default 0)"
          value={ngpus}
          onChange={(e) => setNgpus(e.target.value)}
        />

        <label>walltime: </label>
        <input
          type="text"
          id="walltime"
          name="walltime"
          placeholder="walltime(default 24:00:00)"
          value={walltime}
          onChange={(e) => setWalltime(e.target.value)}
        />

        <div className="output">
          Estimated SU: <span id="su-output">{estimatedSU}</span>
        </div>
        <div className="su-description">
          <span>SU is charged based on the requested CPU and GPU resources as follows: <br></br>
          - For CPU only jobs: 1 SU for 1 ncpus per hour<br></br>
          - For GPU jobs: 64 SU for 1 ngpus per hour (no charge for ncpus)<br></br>
          NOTE: GPU Jobs will have fixed CPU and Memory ratio enforced i.e. for every 1 GPU requested, 16 CPU and 124 GB of memory will be allocated and SU charged will be 64.</span>
        </div>

          <h2> Project Storage (Quota: 312T) </h2>
          <h3> Last Update: {updateStorageTime} </h3>
          <h3>(Automatically updates usage around 1:30am every day)</h3>

          <table style={{ border: '2px solid #555', borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: '#333', color: '#fff' }}>
                <th style={cellStyle}>Name</th>
                <th style={cellStyle}>Used</th>
              </tr>
            </thead>

            <tbody>
              {storage.map((s) => (
                <tr key={s.name}>
                  <td style={cellStyle}>{s.name}</td>
                  <td style={cellStyle}>{s.project}</td>
                </tr>
              ))}
              <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                <td style={cellStyle}>Total</td>
                <td style={cellStyle}>{totalStorageUsage} T</td>
              </tr>
              <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                <td style={cellStyle}>Remaining</td>
                <td style={cellStyle}>{remainningstorage} T</td>
              </tr>

            </tbody>
          </table>

          <h2> SU Daily Usage ({daily_su_use_date}) </h2>
          <h3>(Automatically updates last day's usage around 1:30am every day)</h3>

          <table style={{ border: '2px solid #555', borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: '#333', color: '#fff' }}>
                <th style={cellStyle}>Name</th>
                <th style={cellStyle}>Number of CPU Jobs</th>
                <th style={cellStyle}>SU CPU</th>
                <th style={cellStyle}>Number of GPU Jobs</th>
                <th style={cellStyle}>SU GPU</th>
              </tr>
            </thead>

            <tbody>
              {daily_su_use.map((s) => (
                <tr key={s.name}>
                  <td style={cellStyle}>{s.name}</td>
                  <td style={cellStyle}>{s.n_cpu_hrs}</td>
                  <td style={cellStyle}>{s.cpu_hrs}</td>
                  <td style={cellStyle}>{s.n_gpu_hrs}</td>
                  <td style={cellStyle}>{s.gpu_hrs}</td>
                </tr>
              ))}
              <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                <td style={cellStyle}>Total</td>
                <td style={cellStyle}>{n_total_cpu_daily}</td>
                <td style={cellStyle}>{total_cpu_daily}</td>
                <td style={cellStyle}>{n_total_gpu_daily}</td>
                <td style={cellStyle}>{total_gpu_daily}</td>
              </tr>


            </tbody>
          </table>

          <h2> SU Total Usage (From {startTime} to {end_time}) </h2>
          <h3>(Automatically updates the total usage every week)</h3>

          <table style={{ border: '2px solid #555', borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: '#333', color: '#fff' }}>
                <th style={cellStyle}>Name</th>
                <th style={cellStyle}>Number of CPU Jobs</th>
                <th style={cellStyle}>SU CPU</th>
                <th style={cellStyle}>Number of GPU Jobs</th>
                <th style={cellStyle}>SU GPU</th>
              </tr>
            </thead>

            <tbody>
              {su.map((s) => (
                <tr key={s.name}>
                  <td style={cellStyle}>{s.name}</td>
                  <td style={cellStyle}>{s.n_total_cpu}</td>
                  <td style={cellStyle}>{s.total_cpu}</td>
                  <td style={cellStyle}>{s.n_total_gpu}</td>
                  <td style={cellStyle}>{s.total_gpu}</td>
                </tr>
              ))}
              <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                <td style={cellStyle}>Total</td>
                <td style={cellStyle}>{n_total_cpu_all}</td>
                <td style={cellStyle}>{total_cpu_all}</td>
                <td style={cellStyle}>{n_total_gpu_all}</td>
                <td style={cellStyle}>{total_gpu_all}</td>
              </tr>


            </tbody>
          </table>

        {/* 用户选择器 */}
        <h3 className="user-selector-title">Select a User</h3>
        <select className="user-selector-dropdown" onChange={(e) => onUserSelect(e.target.value)}>
          <option value="">-- Select a User --</option>
          {users.map((user) => (
            <option key={user.username} value={user.username}>
              {user.name} ({user.username})
            </option>
          ))}
        </select>
      </div>
    );
  }
  else {
    return (
      <div className="user-selector-container">
          
        <header className="page-header">
          <h1 className="page-title">Liulab NSCC Usage Summary</h1>
          <h3> v0.0.1 @ Debug </h3>
        </header>

        <h2> SU Calculator </h2>

        <label>ncpus: </label>
        <input
          type="number"
          id="n_cpu_jobs"
          name="n_cpu_jobs"
          placeholder="ncpus(default 0)"
          value={ncpus}
          onChange={(e) => setNcpus(e.target.value)}
        />

        <label>ngpus: </label>
        <input
          type="number"
          id="n_gpu_jobs"
          name="n_gpu_jobs"
          placeholder="ngpus(default 0)"
          value={ngpus}
          onChange={(e) => setNgpus(e.target.value)}
        />

        <label>walltime: </label>
        <input
          type="text"
          id="walltime"
          name="walltime"
          placeholder="walltime(default 24:00:00)"
          value={walltime}
          onChange={(e) => setWalltime(e.target.value)}
        />

        <div className="output">
          Estimated SU: <span id="su-output">{estimatedSU}</span>
        </div>
        <div className="su-description">
          <span>SU is charged based on the requested CPU and GPU resources as follows: <br></br>
          - For CPU only jobs: 1 SU for 1 ncpus per hour<br></br>
          - For GPU jobs: 64 SU for 1 ngpus per hour (no charge for ncpus)<br></br>
          NOTE: GPU Jobs will have fixed CPU and Memory ratio enforced i.e. for every 1 GPU requested, 16 CPU and 124 GB of memory will be allocated and SU charged will be 64.</span>
        </div>

        <h2> Project Storage (Quota: 312T) </h2>
          <h3> Last Update: {updateStorageTime} </h3>
          <h3>(Automatically updates usage around 1:30am every day)</h3>

          <table style={{ border: '2px solid #555', borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: '#333', color: '#fff' }}>
                <th style={cellStyle}>Name</th>
                <th style={cellStyle}>Used</th>
              </tr>
            </thead>

            <tbody>
              {storage.map((s) => (
                <tr key={s.name}>
                  <td style={cellStyle}>{s.name}</td>
                  <td style={cellStyle}>{s.project}</td>
                </tr>
              ))}
              <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                <td style={cellStyle}>Total</td>
                <td style={cellStyle}>{totalStorageUsage} T</td>
              </tr>
              <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                <td style={cellStyle}>Remaining</td>
                <td style={cellStyle}>{remainningstorage} T</td>
              </tr>

            </tbody>
          </table>

          <h2> SU Daily Usage ({daily_su_use_date}) </h2>
          <h3>(Automatically updates last day's usage around 1:30am every day)</h3>

          <table style={{ border: '2px solid #555', borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: '#333', color: '#fff' }}>
                <th style={cellStyle}>Name</th>
                <th style={cellStyle}>Number of CPU Jobs</th>
                <th style={cellStyle}>SU CPU</th>
                <th style={cellStyle}>Number of GPU Jobs</th>
                <th style={cellStyle}>SU GPU</th>
              </tr>
            </thead>

            <tbody>
            {daily_su_use
              .filter((s) => s.username === loginuser)
              .map((s) => (
                <tr key={s.name}>
                  <td style={cellStyle}>{s.name}</td>
                  <td style={cellStyle}>{s.n_cpu_hrs}</td>
                  <td style={cellStyle}>{s.cpu_hrs}</td>
                  <td style={cellStyle}>{s.n_gpu_hrs}</td>
                  <td style={cellStyle}>{s.gpu_hrs}</td>
                </tr>
              ))}
              <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                <td style={cellStyle}>Total</td>
                <td style={cellStyle}>{n_total_cpu_daily}</td>
                <td style={cellStyle}>{total_cpu_daily}</td>
                <td style={cellStyle}>{n_total_gpu_daily}</td>
                <td style={cellStyle}>{total_gpu_daily}</td>
              </tr>


            </tbody>
          </table>

          <h2> SU Total Usage (From {startTime} to {end_time}) </h2>
          <h3>(Automatically updates the total usage every week)</h3>

          <table style={{ border: '2px solid #555', borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: '#333', color: '#fff' }}>
                <th style={cellStyle}>Name</th>
                <th style={cellStyle}>Number of CPU Jobs</th>
                <th style={cellStyle}>SU CPU</th>
                <th style={cellStyle}>Number of GPU Jobs</th>
                <th style={cellStyle}>SU GPU</th>
              </tr>
            </thead>

            <tbody>
              {su
              .filter((s) => s.username === loginuser)
              .map((s) => (
                <tr key={s.name}>
                  <td style={cellStyle}>{s.name}</td>
                  <td style={cellStyle}>{s.n_total_cpu}</td>
                  <td style={cellStyle}>{s.total_cpu}</td>
                  <td style={cellStyle}>{s.n_total_gpu}</td>
                  <td style={cellStyle}>{s.total_gpu}</td>
                </tr>
              ))}
              <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                <td style={cellStyle}>Total</td>
                <td style={cellStyle}>{n_total_cpu_all}</td>
                <td style={cellStyle}>{total_cpu_all}</td>
                <td style={cellStyle}>{n_total_gpu_all}</td>
                <td style={cellStyle}>{total_gpu_all}</td>
              </tr>


            </tbody>
          </table>

        {/* 用户选择器 */}
        <h3 className="user-selector-title">Select a User</h3>
        <select className="user-selector-dropdown" onChange={(e) => onUserSelect(e.target.value)}>
          <option value="">-- Select a User --</option>
          {users.map((user) => (
            <option key={user.username} value={user.username}>
              {user.name} ({user.username})
            </option>
          ))}
        </select>
      </div>
    )
  }
}

const cellStyle = {
  border: '1px solid #555',
  padding: '10px',
  textAlign: 'center',
};

export default UserSelector;