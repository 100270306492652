import React, { useState } from "react";
import Login from "./components/Login";
import UserSelector from "./components/UserSelector";
import UserRecordsTable from "./components/UserRecordsTable";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginuser, setLoginUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleLogin = async (username, password) => {
    try {
      const response = await fetch("https://nsccb.junbingao.com/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      console.log("response:", response);
      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          setIsLoggedIn(true);
          setLoginUser(username);
        } else {
          alert("Invalid credentials");
        }
      } else {
        alert("Error during login process");
      }
    } catch (error) {
      alert("Network error: " + error.message);
    }
  };

  return (
    <div className="App">
      {!isLoggedIn ? (
        <Login onLogin={handleLogin} />
      ) : (
        <div>
          <UserSelector onUserSelect={setSelectedUser} loginuser={loginuser}/>
          {selectedUser && <UserRecordsTable user={selectedUser} loginuser={loginuser} />}
        </div>
      )}
    </div>
  );
}

export default App;