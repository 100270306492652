import React, { useState, useEffect } from "react";
import './Login.css'; // 引入自定义CSS文件

function Login({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // 从localStorage中获取缓存的用户名和密码
  useEffect(() => {
    const cachedUsername = localStorage.getItem("username");
    const cachedPassword = localStorage.getItem("password");

    if (cachedUsername) {
      setUsername(cachedUsername);
    }

    if (cachedPassword) {
      setPassword(cachedPassword);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // 缓存用户名和密码
    localStorage.setItem("username", username);
    localStorage.setItem("password", password);
    onLogin(username, password);
  };

  return (
    <div className="login-container">
      <h1 className="login-title">Login</h1>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="login-form-group">
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="login-input"
          />
        </div>
        <div className="login-form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
        </div>
        <button type="submit" className="login-button">Login</button>
      </form>
    </div>
  );
}

export default Login;