import React, { useState, useEffect } from 'react';

function UserRecordsTable({ user, loginuser }) {
  const [records, setRecords] = useState([]);
  const [storages, setStorages] = useState([]);

  useEffect(() => {
    const fetchUserRecords = async () => {
      try {
        const response = await fetch("https://nsccb.junbingao.com/get_usage", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: user }),
        });
        const data = await response.json();
        if (user === loginuser || loginuser === 'liulab') {
          const userRecord = data.find((record) => record.username === user);
          if (userRecord) {
            setRecords([userRecord]);
          } else {
            setRecords([]);
          }
        }
        else {
          setRecords([]);
        }

        const response_storage = await fetch("https://nsccb.junbingao.com/get_storage_usage", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: user }),
        });
        const data_storage = await response_storage.json();
        if (user === loginuser || loginuser === 'liulab') {
          const userRecord_storage = data_storage.find((record) => record.username === user);
          if (userRecord_storage) {
            setStorages([userRecord_storage]);
          } else {
            setStorages([]);
          }
        }
        else {
          setStorages([]);
        }
      } catch (error) {
        console.error("Error fetching user records:", error);
      }
    };

    fetchUserRecords();
  }, [user]);

  return (
    <div style={{ fontFamily: 'monospace', padding: '20px', maxWidth: '80%', alignContent: 'center', margin: 'auto' }}>
      <h3>Your usage as of {records.length > 0 ? records[0].report_date : 'N/A'}</h3>
      <table style={{ border: '2px solid #555', borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr style={{ backgroundColor: '#333', color: '#fff' }}>
            <th style={cellStyle}>Time Range</th>
            <th style={cellStyle}>Resource</th>
            <th style={cellStyle}>Num of Jobs</th>
            <th style={cellStyle}>Core/Card Hours</th>
          </tr>
        </thead>
        <tbody>
          {/* Past 7 Days */}
          <tr>
            <td rowSpan="2" style={cellStyle}>Past 7 days</td>
            <td style={cellStyle}>CPU</td>
            <td style={cellStyle}>{records.length > 0 ? records[0].n_cpu_hrs_7d : 'N/A'}</td>
            <td style={cellStyle}>{records.length > 0 ? records[0].cpu_hrs_7d : 'N/A'}</td>
          </tr>
          <tr>
            <td style={cellStyle}>GPU</td>
            <td style={cellStyle}>{records.length > 0 ? records[0].n_gpu_hrs_7d : 'N/A'}</td>
            <td style={cellStyle}>{records.length > 0 ? records[0].gpu_hrs_7d : 'N/A'}</td>
          </tr>

          {/* Past 30 Days */}
          <tr>
            <td rowSpan="2" style={cellStyle}>Past 30 days</td>
            <td style={cellStyle}>CPU</td>
            <td style={cellStyle}>{records.length > 0 ? records[0].n_cpu_hrs_30d : 'N/A'}</td>
            <td style={cellStyle}>{records.length > 0 ? records[0].cpu_hrs_30d : 'N/A'}</td>
          </tr>
          <tr>
            <td style={cellStyle}>GPU</td>
            <td style={cellStyle}>{records.length > 0 ? records[0].n_gpu_hrs_30d : 'N/A'}</td>
            <td style={cellStyle}>{records.length > 0 ? records[0].gpu_hrs_30d : 'N/A'}</td>
          </tr>

          {/* Overall */}
          <tr>
            <td rowSpan="2" style={cellStyle}>Overall</td>
            <td style={cellStyle}>CPU</td>
            <td style={cellStyle}>{records.length > 0 ? records[0].n_cpu_hrs_oa : 'N/A'}</td>
            <td style={cellStyle}>{records.length > 0 ? records[0].cpu_hrs_oa : 'N/A'}</td>
          </tr>
          <tr>
            <td style={cellStyle}>GPU</td>
            <td style={cellStyle}>{records.length > 0 ? records[0].n_gpu_hrs_oa : 'N/A'}</td>
            <td style={cellStyle}>{records.length > 0 ? records[0].gpu_hrs_oa : 'N/A'}</td>
          </tr>
        </tbody>
      </table>

      <h3>Your Storage usage as of {storages.length > 0 ? storages[0].report_date : 'N/A'} </h3>
      <h3> Home Storage used: {storages.length > 0 ? storages[0].home : 'N/A'} </h3>
      <h3> Project Storage used: {storages.length > 0 ? storages[0].project : 'N/A'} </h3>
    </div>
  );
}

const cellStyle = {
  border: '1px solid #555',
  padding: '10px',
  textAlign: 'center',
};

export default UserRecordsTable;